<template>
    <loader v-if="loading && !getAppLoading" :style="'box'"></loader>
    <div v-if="!loading && pageContent !== null" class="flexgrid-demo" ref="pageWrapper">
        <page-canvas v-if="pageContent.type === 'canvas'" :page="page" :parentPageContent="pageContent"></page-canvas>
        <page-widgets v-if="pageContent.type === 'grid'" :page="page"></page-widgets>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import store from '@/store';
// import EventBus from '../event-bus';
import { mongoResponseToast } from '../helpers';
import { mapGetters, mapActions } from 'vuex'
import SocketioService from '@/services/socketioService.js';
import { addEventListener, removeEventListener } from '@/services/eventListener';
import { getRandomInt, findIndexByTag } from '../helpers';
import pageCanvas from '@/components/pageCanvas';
import pageWidgets from '@/components/pageWidgets';

export default defineComponent({
    name: 'Page',
    mixins: [addEventListener, removeEventListener],
    components: {
        pageCanvas,
        pageWidgets,
        // VueKonva
        // Draggable,
        // theTable3
    },
    setup() {
        // const primeChart = ref();
    },
    data() {
        return {
            layout: [
                { x: 0, y: 0, w: 2, h: 2, i: '1040', static: false, label: 'meister' },
                { x: 2, y: 0, w: 2, h: 4, i: '101', static: false, label: 'meister2' },
            ],
            editMode: false,
            draggable: true,
            resizable: true,
            index: 0,
            grid: null,
            count: {
                value: 0,
            },
            // primeChart: null,
            loading: false,
            createdLayout: false,
            mountedLayout: false,
            readyLayout: false,
            drag: false,
            cnt: 0,
            values: [],
            pageContent: null,
            paramList: null,
            // componentKey: "dashboardPanelValues",
        };
    },
    props: {
        // paramList: Object,
        page: {
            type: String,
            required: true,
        },
        appContent: Boolean,
        componentKey: String,
    },
    computed: {
        ...mapGetters({
            getAppLoading: 'getAppLoading',
            getNodeWidget: 'getNodeWidget',
            getOneDepartment: 'types/getOneDepartment',
            getOneCategory: 'types/getOneCategory',
            getIoTree: 'getIoTree',
            getStyleColors: 'getStyleColors',
            getStylePatterns: 'getStylePatterns',
            getModules: 'opcua/getModules',
            getModule: 'opcua/getModule',
            getNode: 'opcua/getNode',
            getLoadedState: 'opcua/getLoadedState',
            isMaster: 'auth/isMaster',
            currentTimeFormatted: 'currentTimeFormatted',
        }),
        isEdit: function () {
            if (store.getters.getPageData.isEdit === true) {
                return true;
            } else {
                return false;
            }
        },
        myList: function () {
            return [
                { id: 1, value: 'Card A' },
                { id: 2, value: 'Card B' },
            ];
        },
        siteName: function () {
            return this.$route.params.page;
        },
        dragOptions() {
            return {
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost',
            };
        },
        v_text: function() {
            const vtext = [];
            const obj = {
                text: this.currentTimeFormatted,
                x: 150,
                y: 150,
                width: 200,
                scaleX: 1,
                fontSize: 20,
                draggable: true,
                name: "helloWorld",
                fill: "white"
            };
            vtext.push(obj);
            return vtext;
        },
        v_rect: function() {
            const vrect = [];
            const obj = {
                text: this.currentTimeFormatted,
                x: 500,
                y: 400,
                width: 100,
                height: 150,
                draggable: true,
                name: "rect",
                fill: "white"
            };
            vrect.push(obj);
            return vrect;
        },
        stageWidth() {
            return this.size.width % 2 !== 0 ? this.size.width - 1 : this.size.width;
        },
        stageHeight() {
            return this.size.height % 2 !== 0 ? this.size.height - 1 : this.size.height;
        },
    },
    watch: {
        eventLog: function () {
            // const eventsDiv = this.$refs.eventsDiv;
            // eventsDiv.scrollTop = eventsDiv.scrollHeight;
        },
        '$store.getters.getPageData.editMode': function () {
            this.checkEditMode();
        },
        addWidgetEvent: function(widget) {
          console.log("WIDGET EVENT ", widget);
        },
    },
    created() {
        this.$watch(
            () => this.$route.params,
            (toParams, previousParams) => {
                console.log("APP_PAGE PREV:", previousParams, "TO:", toParams)
                this.getPageData();
                this.$store.dispatch('setPath', { name: this.pageContent.name, label: this.pageContent.label });
            }
        );
        // this.getPageData();
        // store.dispatch("logout");
        this.image = new Image();
        this.image.src = "https://konvajs.org/assets/darth-vader.jpg";
        this.image.name = "vader";
        window.addEventListener('resize', this.checkSize);
    },
    methods: {
        ...mapActions({
            loadModules: 'opcua/loadModules',
        }),
        itemTitle(item) {
            let result = item.i;
            if (item.static) {
                result += ' - Static';
            }
            return result;
        },
        checkEditMode() {
            if (this.$store.getters.getPageData.editMode === true) {
                this.editMode = true;
            } else {
                this.editMode = false;
            }
        },
        moveEvent: function (i, newX, newY) {
            const msg = 'MOVE i=' + i + ', X=' + newX + ', Y=' + newY;
            // this.eventLog.push(msg);
            if (this.editMode) {
                console.log(msg);
            }
        },
        movedEvent: function (i, newX, newY) {
            const msg = 'MOVED i=' + i + ', X=' + newX + ', Y=' + newY;
            // this.eventLog.push(msg);
            if (this.editMode) {
                console.log(msg);
            }
        },
        resizeEvent: function (i, newH, newW, newHPx, newWPx) {
            const msg = 'RESIZE i=' + i + ', H=' + newH + ', W=' + newW + ', H(px)=' + newHPx + ', W(px)=' + newWPx;
            // this.eventLog.push(msg);
            if (this.editMode) {
                console.log(msg);
            }
        },
        resizedEvent: function (i, newX, newY, newHPx, newWPx) {
            const msg = 'RESIZED i=' + i + ', X=' + newX + ', Y=' + newY + ', H(px)=' + newHPx + ', W(px)=' + newWPx;
            // this.eventLog.push(msg);
            if (this.editMode) {
                console.log(msg);
            }
        },
        containerResizedEvent: function (i, newH, newW, newHPx, newWPx) {
            const msg = 'CONTAINER RESIZED i=' + i + ', H=' + newH + ', W=' + newW + ', H(px)=' + newHPx + ', W(px)=' + newWPx;
            // this.eventLog.push(msg);
            if (this.editMode) {
                console.log(msg);
            }
        },
        layoutCreatedEvent: function (newLayout) {
            // this.eventLog.push("Created layout");\
            if (this.editMode) {
                console.log('Created layout: ', newLayout);
            }
            this.createdLayout = true;
        },
        layoutBeforeMountEvent: function (newLayout) {
            // this.eventLog.push("beforeMount layout");
            if (this.editMode) {
                console.log('beforeMount layout: ', newLayout);
            }
        },
        layoutMountedEvent: function (newLayout) {
            // this.eventLog.push("Mounted layout");
            if (this.editMode) {
                console.log('Mounted layout: ', newLayout);
            }
            this.mountedLayout = true;
        },
        layoutReadyEvent: function (newLayout) {
            // this.eventLog.push("Ready layout");
            if (this.editMode) {
                console.log('Ready layout: ', newLayout);
            }
            this.readyLayout = true;
        },
        layoutUpdatedEvent: function (newLayout) {
            // this.eventLog.push("Updated layout");
            if (this.editMode) {
                console.log('Updated layout: ', newLayout);
            }
            // console.log("PageContent layout: ", this.pageContent)
            // this.changeVal(null);
        },
        logger(evt) {
            console.log(evt);
        },
        addNewWidget() {
            const node = {
                x: Math.round(12 * Math.random()),
                y: Math.round(5 * Math.random()),
                w: Math.round(1 + 3 * Math.random()),
                h: Math.round(1 + 3 * Math.random()),
            };
            this.items.value.push(node);
        },
        addData() {
            // // const chart = this.$refs.primeChart.value.chart;
            // const chart = this.$refs.primeChart.chart;
            // let rndm = getRandomInt(0, 100);
            // // chart.data.datasets[0].data.push(9);
            // console.log(chart.data.datasets[0].data);
            // this.basicData.datasets[0].data[4] = rndm;
            // // chart.data.labels.push(/* NEW DATA LABEL */)
            // // chart.data.datasets[0].data.push(/* NEW DATA VALUE */)
            // // chart.update();
        },
        getPageData() {
            this.loading = true;
            if (this.siteName !== null) {
                console.log(this.siteName);
                SocketioService.getViewPageEntry(this.siteName, (err, response) => {
                    if (!err && response !== null) {
                        this.pageContent = response;
                        console.log(this.pageContent.type);
                        this.loading = false;
                        if (this.pageContent.type === 'grid' || this.pageContent.type === 'boxes' || this.pageContent.type === 'canvas') {
                            // store.dispatch('setPageData', { editMode: true });
                            store.dispatch('setPageData', { name: this.pageContent.name, label: this.pageContent.label });
                        }
                    }
                });
            }
        },
        getParameterStatusIO() {
            // this.loading = true;
            SocketioService.getStatusIO((response) => {
                this.paramList = response;
                // this.loading = false;
            });
        },
        addColumn() {
            let index, index2;
            // this.grids = [...this.grids, 1];
            // index = (this.grids.length - 1);
            index = this.grids.length;
            this.grids[index] = [];
            index2 = this.grids[index].length;
            console.log(index2);
            this.grids[index][index2] = {};
            this.grids[index][index2].id = index + ':' + index2;
            this.grids[index][index2].name = 'GUSCH';
            console.log(this.grids);
        },
        removeColumn() {
            this.grids.pop();
        },
        generateId() {
            let rndm = getRandomInt(0, 65535);
            const index = findIndexByTag(this.pageContent.components, 'i', rndm);
            if (index === -1) {
                return rndm;
            } else {
                this.generateId();
            }
        },
        duplicateWidget(settings) {
            let index;
            let widget;
            // this.grids = [...this.grids, 1];
            // index = (this.grids.length - 1);
            index = this.pageContent.components.length;
            console.log(index);
            // this.pageContent.components[index] = [];
            console.log(settings);
            if (settings !== null) {
                widget = { ...settings };
                widget.y = widget.y + 2;
                // widget.id = index;
                const rndm = this.generateId();
                widget.i = rndm;
            }
            this.changeVal(widget);
        },
        addWidget(settings) {
            let index;
            let widget;
            // this.grids = [...this.grids, 1];
            // index = (this.grids.length - 1);
            index = this.pageContent.components.length;
            console.log(index);
            // this.pageContent.components[index] = [];
            console.log(settings);
            if (settings !== null) {
                let data = null;
                if (settings.isArray) {
                    data = [
                        {
                            id: '01'
                        },
                        {
                            id: '02'
                        }
                    ]
                } else {
                    data = {
                        id: '01'
                    }
                }
            const rndm = this.generateId();
              const lastWidget = {...this.pageContent.components[index-1]};
                widget = {
                    // id: index,
                    // id: getRandomInt(0, 65535),
                    // i: getRandomInt(0, 65535),
                    i: rndm,
                    name: 'Neues Widget',
                    label: 'Neues Widget',
                    component: settings.component,
                    width: 2,
                    style: {
                        background: 'darkBlue',
                        foreground: 'white',
                    },
                    data: data,
                    static: false,
                    moved: false,
                    y: lastWidget.y + 2,
                    x: lastWidget.x,
                    h: 2,
                    w: 2,
                };
            }
            // this.pageContent.components.push(widget);
            this.changeVal(widget);
            // console.log(this.pageContent.components);

            // index2 = this.pageContent.components[index].length;
            // console.log(index2);
            // this.pageContent.components[index][index2] = {};
            // this.pageContent.components[index][index2].id = index + ":" + index2;
            // this.pageContent.components[index][index2].name = "GUSCH";
            // console.log(this.grids);
        },
        dragOff(event) {
            console.log(event);
            this.changeVal(null);
            this.drag = false;
            // console.log(this.grids3);
        },
        getIndex(id) {
            var index = this.pageContent.components.findIndex((x) => x.id === id);
            if (index === -1) {
                // this.values.push(vars);
                console.log('NOT FOUND');
                return -1;
            } else {
                // this.values[index] = vars;
                // return this.pageContent.components[index];
                return index;
            }
        },
        getGridIndex(id) {
            var index = this.pageContent.components.findIndex((x) => x.i === id);
            if (index === -1) {
                // this.values.push(vars);
                console.log('NOT FOUND');
                return -1;
            } else {
                // this.values[index] = vars;
                // return this.pageContent.components[index];
                return index;
            }
        },
        savePage(callback) {
            SocketioService.setViewPage(this.pageContent, (err, response) => {
                if (err || response === null) {
                    callback(false);
                } else {
                    // this.loading = false;
                    const result = mongoResponseToast(response, this.$root);
                    if (result) callback(true);
                }
            });
        },
        changeVal(entry) {
            console.log(">>> PAGE CHANGEVAL:", entry);
            if (entry !== null) {
                // const index = this.getIndex(entry.id);
                const index = this.getGridIndex(entry.i);
                console.log('INDEX is: ' + index);
                if (index === -1) {
                    this.pageContent.components.push(entry);
                } else {
                    this.pageContent.components[index] = entry;
                }
            }
        },
        duplicateVal(entry) {
            this.duplicateWidget(entry);
        },
        deleteVal(entry) {
            const index = this.getGridIndex(entry.i);
            this.pageContent.components.splice(index, 1);
        },
        onNodeSelect(node) {
            this.$toast.add({
                severity: 'success',
                summary: 'Node Selected',
                detail: node,
                life: 3000,
            });
        },
        getParameterStatusIOTree() {
            //store.dispatch('getIoTree', null);
        },
    },
    mounted() {
        // EventBus.on('addWidget', (widget) => {
        //   console.log(widget);
        //   this.addWidget(widget);
        // });
        // EventBus.on('savePage', (callback) => {
        //   this.savePage(result => {
        //       console.log("Page saved");
        //       callback(result);
        //   });
        // });
        // this.$router.push({ path: this.siteName });
        this.getPageData();
        this.getParameterStatusIOTree();
    },
    // DEBUG
    beforeUnmount() {
        // EventBus.off('addWidget');
        // EventBus.off('savePage');
        window.removeEventListener('resize', this.checkSize);
    },
});
</script>

<style lang="scss" scoped>
</style>
