<template>
    <div class="flexgrid-demo" ref="pageArea">
        <div v-if="!loading" ref="container" @contextmenu="toggleCanvasMenu">
            <ContextMenu v-if="getEditMode" ref="canvasMenu" :model="menuEntries" />
            <!-- <v-stage ref="stage" :config="stageSize" @mousedown="handleStageMouseDown"> -->
            <v-stage ref="stage" class="col-12" :config="size" @mousedown="handleStageMouseDown">
                <v-layer ref="layer-images">
                    <v-group ref="imageGroup">
                        <v-image v-for="image in Object.values(images)" :key="image.id" :config="image"/>
                    </v-group>
                    <v-transformer ref="transformer" />
                </v-layer>
                <v-layer ref="layer-rect">
                    <v-group ref="rectGroup">
                        <v-rect v-for="item in canvasRectVars" :key="item.name" :config="item" @dblclick="toggleEditDiag(event, item.name)" @dragstart="moveStart(item)" @dragend="moveEvent($event, item)" @transformend="transformEvent($event, item)" @mouseenter="enterEvent($event, item)" @mouseleave="leaveEvent($event, item)" />
                    </v-group>
                    <v-transformer ref="rectTransformer" />
                </v-layer>
                <v-layer ref="layer-circle">
                    <v-group ref="circleGroup">
                        <v-circle v-for="item in canvasCircleVars" :key="item.name" :config="item" @dblclick="toggleEditDiag(event, item.name)" @dragstart="moveStart(item)" @dragend="moveEvent($event, item)" @transformend="transformEvent($event, item)" @mouseenter="enterEvent($event, item)" @mouseleave="leaveEvent($event, item)" />
                    </v-group>
                    <v-transformer ref="circleTransformer" />
                </v-layer>
                <v-layer ref="layer-picture">
                    <v-group ref="pictureGroup">
                        <v-image v-for="item in canvasPictureVars" :key="item.name" :config="item" @dblclick="toggleEditDiag(event, item.name)" @dragstart="moveStart(item)" @dragend="moveEvent($event, item)" @transformend="transformEvent($event, item)" @mouseenter="enterEvent($event, item)" @mouseleave="leaveEvent($event, item)" />
                    </v-group>
                    <v-transformer ref="pictureTransformer" />
                </v-layer>
                <v-layer ref="layer-text">
                    <!-- <v-text v-for="item in v_text" :key="item.name" :config="item" /> -->
                    <v-group ref="textGroup">
                        <v-text v-for="item in canvasTextVars" :key="item.name" :config="item" @dblclick="toggleEditDiag(event, item.name)" @dragstart="moveStart(item)" @dragend="moveEvent($event, item)" @transformend="transformEvent($event, item)" @mouseenter="enterEvent($event, item)" @mouseleave="leaveEvent($event, item)" />
                    </v-group>
                    <v-transformer ref="textTransformer" />
                </v-layer>
            </v-stage>
        </div>
    </div>
    <div v-if="txtDiag">
        <dialog-canvas-txt v-bind:showDialog="txtDiag" :dialogObj="null" v-bind:dialogData="editDialogData"
            @entrySave="($value) => {
                changeVal($value);
            }"
            @entryAbort="hideEditDiag()"
        ></dialog-canvas-txt>
    </div>
    <div v-if="rectDiag">
        <dialog-canvas-rect v-bind:showDialog="rectDiag" :dialogObj="null" v-bind:dialogData="editDialogData"
            @entrySave="($value) => {
                changeVal($value);
            }"
            @entryAbort="hideEditDiag()"
        ></dialog-canvas-rect>
    </div>
    <div v-if="circleDiag">
        <dialog-canvas-circle v-bind:showDialog="circleDiag" :dialogObj="null" v-bind:dialogData="editDialogData"
            @entrySave="($value) => {
                changeVal($value);
            }"
            @entryAbort="hideEditDiag()"
        ></dialog-canvas-circle>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
// import store from '@/store';
import EventBus from '../event-bus';
import { mongoResponseToast } from '../helpers';
import { mapGetters, mapActions } from 'vuex'
import SocketioService from '@/services/socketioService.js';
import { addEventListener, removeEventListener } from '@/services/eventListener';
import { findIndexByTag, findObjectById, splitNodeId, formatUnit } from '../helpers';
import { uuid } from 'vue-uuid';
import dialogCanvasTxt from '@/components/dialog/dialogCanvasTxt';
import dialogCanvasRect from '@/components/dialog/dialogCanvasRect';
import dialogCanvasCircle from '@/components/dialog/dialogCanvasCircle';

const SCENE_BASE_WIDTH = 800;
const SCENE_BASE_HEIGHT = 600;

export default defineComponent({
    name: 'pageCanvas',
    mixins: [addEventListener, removeEventListener],
    components: {
        dialogCanvasTxt,
        dialogCanvasRect,
        dialogCanvasCircle,
    },
    setup() {
        // const primeChart = ref();
    },
    data() {
        return {
            txtDiag: false,
            rectDiag: false,
            circleDiag: false,
            editDialogData: null,
            // imagesProps: [
            //     "https://picsum.photos/200/300?image=0",
            //     "https://picsum.photos/200/300?image=1",
            //     "https://picsum.photos/200/300?image=3"
            // ],
            imagesProps: [
            ],
            images: {},
            // stageSize: {
            //     width: width,
            //     height: height
            // },
            size: {
                width: window.innerWidth,
                height: window.innerHeight
                // width: 0,
                // height: 0
            },
            canvasTextVarArr: [],
            canvasRectArr: [],
            canvasCircleArr: [],
            canvasPictureArr: [],
            sceneScale: 1,
            isDragging: false,
            dragObject: null,
            selectedShapeName: "",
            selectedNode: null,
            imageGroup: null,
            transformer: null,
            textTransformer: null,
            rectTransformer: null,
            circleTransformer: null,
            pictureTransformer: null,
            stageArea: null,
            pageArea: null,
            image: null,
            // v_text: [
            //     {
            //     text: this.currentTimeFormatted,
            //     x: 150,
            //     y: 150,
            //     width: 200,
            //     scaleX: 1,
            //     fontSize: 20,
            //     draggable: true,
            //     name: "helloWorld",
            //     fill: "white"
            //     }
            // ],
            layout: [
                { x: 0, y: 0, w: 2, h: 2, i: '1040', static: false, label: 'meister' },
                { x: 2, y: 0, w: 2, h: 4, i: '101', static: false, label: 'meister2' },
            ],
            editMode: false,
            draggable: true,
            resizable: true,
            index: 0,
            grid: null,
            count: {
                value: 0,
            },
            // primeChart: null,
            loading: false,
            createdLayout: false,
            mountedLayout: false,
            readyLayout: false,
            drag: false,
            cnt: 0,
            values: [],
            pageContent: null,
            paramList: null,
            menuEntries: [
                {
                    label: 'Text',
                    icon: 'fi fi-rr-text',
                    command: () => {
                        this.addElementToCanvas('text');
                    },
                },
                {
                    label: 'Rechteck',
                    icon: 'fi fi-rr-form',
                    command: () => {
                        this.addElementToCanvas('rect');
                    },
                },
                {
                    label: 'Kreis',
                    icon: 'fi fi-rr-circle',
                    command: () => {
                        this.addElementToCanvas('circle');
                    },
                },
                {
                    label: 'Bild',
                    icon: 'fi fi-rr-picture',
                    command: () => {
                        this.addElementToCanvas('picture');
                    },
                },
            ],
            // componentKey: "dashboardPanelValues",
        };
    },
    props: {
        // paramList: Object,
        page: {
            type: String,
            required: true,
        },
        parentPageContent: {
            type: Object,
            required: true
        },
        appContent: Boolean,
        componentKey: String,
    },
    computed: {
        ...mapGetters({
            getAppLoading: 'getAppLoading',
            getEditMode: 'getEditMode',
            getNodeWidget: 'getNodeWidget',
            getOneDepartment: 'types/getOneDepartment',
            getOneCategory: 'types/getOneCategory',
            getIoTree: 'getIoTree',
            getStyleColors: 'getStyleColors',
            getStylePatterns: 'getStylePatterns',
            getModules: 'opcua/getModules',
            getModule: 'opcua/getModule',
            getNode: 'opcua/getNode',
            getLoadedState: 'opcua/getLoadedState',
            isMaster: 'auth/isMaster',
            currentTimeFormatted: 'currentTimeFormatted',
        }),
        isEdit: function () {
            // if (store.getters.getPageData.isEdit === true) {
            //     return true;
            // } else {
            //     return false;
            // }
            return this.getEditMode;
        },
        canvasTextVars: function() {
            const vtext = [];
            this.canvasTextVarArr.forEach((entry) => {
                let txt = null;
                if (entry.data.key) {
                    const splittedId = splitNodeId(entry.data.key);
                    const key = `${entry.data.server}@${splittedId.identifier}`;
                    this.loadNodeOnce(key);
                    const node = this.getNode(key);
                    if (node.identifier) {
                        const nodeTxt = formatUnit(node);
                        // if (entry.style.hideUnit) {
                        //     txt = `${node.value}`;
                        // } else {
                        //     txt = `${node.value} ${node.unit}`;
                        // }
                        if (entry.style.hideUnit) {
                            txt = `${nodeTxt}`;
                        } else {
                            txt = `${nodeTxt} ${node.unit}`;
                        }
                    }
                } else if (entry.data.text) {
                    txt = `${entry.data.text}`;
                } else {
                    txt = `Text`;
                }
                let prelabel = "";
                let postlabel = "";
                let shadow = {
                    color: "black",
                    blur: 1,
                    opacity: 0,
                    offset: { x: 1, y: 1 }
                }
                let stroke = 0;
                // if (entry.data.labelSpecial !== undefined) node.label = entry.data.labelSpecial;
                if (entry.extra.prelabel !== undefined) prelabel = `${entry.extra.prelabel} `;
                if (entry.extra.postlabel !== undefined) postlabel = ` ${entry.extra.postlabel}`;
                if (entry.style.shadow) shadow.opacity = 0.4;
                if (entry.style.outline) stroke = 1;
                const obj = {
                    text: `${prelabel}${txt}${postlabel}`,
                    x: entry.x,
                    y: entry.y,
                    z: entry.z,
                    rotation: entry.rotation,
                    // scaleX: entry.scaleX,
                    // scaleY: entry.scaleY,
                    fontSize: entry.style.fontsize,
                    shadow: entry.style.shadow,
                    draggable: this.getEditMode,
                    name: entry.id,
                    fill: entry.style.fgcol,
                    shadowColor: shadow.color,
                    shadowBlur: shadow.blur,
                    shadowOpacity: shadow.opacity,
                    shadowOffset: shadow.offset,
                    stroke: entry.style.bgcol,
                    strokeWidth: stroke
                };
                vtext.push(obj);
            });
            vtext.sort(this.sortAfterZ);
            return vtext;
        },
        canvasRectVars: function() {
            const retArr = [];
            this.canvasRectArr.forEach((entry) => {
                let shadow = {
                    color: "black",
                    blur: 1,
                    opacity: 0,
                    offset: { x: 1, y: 1 }
                }
                let stroke = 0;
                if (entry.style.shadow) shadow.opacity = 0.4;
                if (entry.style.outline && entry.style.outlineStroke > 0) stroke = entry.style.outlineStroke;
                else stroke = 0;
                const obj = {
                    x: entry.x,
                    y: entry.y,
                    z: entry.z,
                    width: entry.width,
                    height: entry.height,
                    rotation: entry.rotation,
                    cornerRadius: entry.style.cornerRadius,
                    // scaleX: entry.scaleX,
                    // scaleY: entry.scaleY,
                    draggable: this.getEditMode,
                    name: entry.id,
                    fill: entry.style.fgcol,
                    shadowColor: shadow.color,
                    shadowBlur: shadow.blur,
                    shadowOpacity: shadow.opacity,
                    shadowOffset: shadow.offset,
                    stroke: entry.style.bgcol,
                    strokeWidth: stroke

                };
                retArr.push(obj);
            });
            retArr.sort(this.sortAfterZ);
            return retArr;
        },
        canvasCircleVars: function() {
            const retArr = [];
            this.canvasCircleArr.forEach((entry) => {
                let shadow = {
                    color: "black",
                    blur: 1,
                    opacity: 0,
                    offset: { x: 1, y: 1 }
                }
                let stroke = 0;
                if (entry.style.shadow) shadow.opacity = 0.4;
                if (entry.style.outline && entry.style.outlineStroke > 0) stroke = entry.style.outlineStroke;
                else stroke = 0;
                const obj = {
                    x: entry.x,
                    y: entry.y,
                    z: entry.z,
                    radius: entry.radius,
                    rotation: entry.rotation,
                    draggable: this.getEditMode,
                    name: entry.id,
                    fill: entry.style.fgcol,
                    shadowColor: shadow.color,
                    shadowBlur: shadow.blur,
                    shadowOpacity: shadow.opacity,
                    shadowOffset: shadow.offset,
                    stroke: entry.style.bgcol,
                    strokeWidth: stroke

                };
                retArr.push(obj);
            });
            retArr.sort(this.sortAfterZ);
            return retArr;
        },
        canvasPictureVars: function() {
            const retArr = [];
            this.canvasPictureArr.forEach((entry) => {
                let shadow = {
                    color: "black",
                    blur: 1,
                    opacity: 0,
                    offset: { x: 1, y: 1 }
                }
                let stroke = 0;
                if (entry.style.shadow) shadow.opacity = 0.4;
                if (entry.style.outline && entry.style.outlineStroke > 0) stroke = entry.style.outlineStroke;
                else stroke = 0;
                const obj = {
                    x: entry.x,
                    y: entry.y,
                    z: entry.z,
                    width: entry.width,
                    height: entry.height,
                    rotation: entry.rotation,
                    // scaleX: entry.scaleX,
                    // scaleY: entry.scaleY,
                    draggable: true,
                    name: entry.id,
                    fill: entry.style.fgcol,
                    shadowColor: shadow.color,
                    shadowBlur: shadow.blur,
                    shadowOpacity: shadow.opacity,
                    shadowOffset: shadow.offset,
                    stroke: entry.style.bgcol,
                    strokeWidth: stroke

                };
                retArr.push(obj);
            });
            retArr.sort(this.sortAfterZ);
            return retArr;
        },
        v_text: function() {
            const vtext = [];
            const obj = {
                text: this.currentTimeFormatted,
                x: 150,
                y: 150,
                width: 200,
                scaleX: 1,
                fontSize: 20,
                draggable: true,
                name: "helloWorld",
                fill: "white"
            };
            vtext.push(obj);
            return vtext;
        },
        stageWidth() {
            return this.size.width % 2 !== 0 ? this.size.width - 1 : this.size.width;
        },
        stageHeight() {
            return this.size.height % 2 !== 0 ? this.size.height - 1 : this.size.height;
        },
    },
    watch: {
        eventLog: function () {
            // const eventsDiv = this.$refs.eventsDiv;
            // eventsDiv.scrollTop = eventsDiv.scrollHeight;
        },
        '$store.getters.getPageData.editMode': function () {
            this.checkEditMode();
        },
        addWidgetEvent: function(widget) {
          console.log("WIDGET EVENT ", widget);
        },
    },
    created() {
        this.image = new Image();
        this.image.src = "https://konvajs.org/assets/darth-vader.jpg";
        this.image.name = "vader";
        window.addEventListener('resize', this.checkSize);
        this.pageContent = this.parentPageContent;
        this.loadCanvasArrays();
    },
    mounted() {
        EventBus.on('addCanvasElement', (widget) => {
          console.log(widget);
          this.addWidget(widget);
        });
        EventBus.on('savePage', (callback) => {
          this.savePage(result => {
              console.log("Page saved");
              callback(result);
          });
        });
        // this.$router.push({ path: this.siteName });
        // this.getPageData();
        // this.getParameterStatusIOTree();

        this.transformer = this.$refs.transformer.getStage();
        this.textTransformer = this.$refs.textTransformer.getStage();
        this.rectTransformer = this.$refs.rectTransformer.getStage();
        this.circleTransformer = this.$refs.circleTransformer.getStage();
        this.pictureTransformer = this.$refs.pictureTransformer.getStage();
        this.imageGroup = this.$refs.imageGroup.getStage();
        this.textGroup = this.$refs.textGroup.getStage();
        this.rectGroup = this.$refs.rectGroup.getStage();
        this.circleGroup = this.$refs.circleGroup.getStage();
        this.pictureGroup = this.$refs.pictureGroup.getStage();
        this.stageArea = this.$refs.stage.getStage();
        this.canvas = this.$refs.container;
        this.pageArea = this.$refs.pageArea;
        this.calculateCanvas();
        this.registerImages();
    },
    beforeUnmount() {
        EventBus.off('addCanvasElement');
        EventBus.off('savePage');
        window.removeEventListener('resize', this.checkSize);
    },
    methods: {
        ...mapActions({
            loadModules: 'opcua/loadModules',
            loadNode: 'opcua/loadNode',
            loadNodeOnce: 'opcua/loadNodeOnce',
        }),
        loadCanvasArrays() {
            this.canvasTextVarArr = [];
            this.canvasRectArr = [];
            this.canvasCircleArr = [];
            this.canvasPictureArr = [];
            if (Array.isArray(this.pageContent.components)) {
                this.pageContent.components.forEach((element) => {
                    if ((element !== undefined) && (element.type !== undefined)) {
                        if (element.type === "text" || element.type === "textvar") {
                            this.canvasTextVarArr.push(element);
                        } else if (element.type === "rect") {
                            this.canvasRectArr.push(element);
                        } else if (element.type === "circle") {
                            this.canvasCircleArr.push(element);
                        } else if (element.type === "picture") {
                            this.canvasPictureArr.push(element);
                        }
                    }
                });
            }
        },
        toggleEditDiag(event, id) {
            if (this.getEditMode && this.pageContent !== undefined && this.pageContent.components !== undefined && id !== undefined) {
                const result = findObjectById(this.pageContent.components, id);
                if (result) {
                    this.editDialogData = { ...result };
                    if (this.editDialogData.type === 'text' || this.editDialogData.type === 'textvar') {
                        this.txtDiag = true;
                    } else if (this.editDialogData.type === 'rect') {
                        this.rectDiag = true;
                    } else if (this.editDialogData.type === 'circle') {
                        this.circleDiag = true;
                    }
                }
            }
            // this.this.getModuleValueStatusIOTree();
            // this.getOPCAppTree();
        },
        toggleCanvasMenu(event) {
            if (this.getEditMode) this.$refs.canvasMenu.show(event);
        },
        addElementToCanvas(element) {
            if (this.getEditMode && element !== undefined) {
                let obj = null;
                let x = this.stageArea.getPointerPosition().x / this.sceneScale;
                let y = this.stageArea.getPointerPosition().y / this.sceneScale;
                if (x <= 0) x = 50;
                if (y <= 0) y = 50;
                if (element === 'text') {
                    obj = {
                        id: uuid.v4(),
                        name: "label",
                        type: "text",
                        x: x,
                        y: y,
                        z: 1,
                        rotation: 0,
                        style: {
                            fgcol: "red",
                            bgcol: "",
                            font: "",
                            fontsize: 14,
                            fontweight: "",
                            effects: false,
                        },
                        data: {
                            text: "Text"
                        },
                        extra: {
                            prelabel: "",
                            postlabel: ""
                        }
                    }
                } else if (element === 'rect') {
                    obj = {
                        id: uuid.v4(),
                        name: "rect",
                        type: "rect",
                        x: x,
                        y: y,
                        z: 1,
                        width: 80,
                        height: 50,
                        rotation: 0,
                        style: {
                            fgcol: "blue",
                            bgcol: "",
                            effects: false
                        },
                    }
                } else if (element === 'circle') {
                    obj = {
                        id: uuid.v4(),
                        name: "circle",
                        type: "circle",
                        x: x,
                        y: y,
                        z: 1,
                        radius: 50,
                        rotation: 0,
                        style: {
                            fgcol: "yellow",
                            bgcol: "",
                            effects: false
                        },
                    }
                } else if (element === 'picture') {
                    obj = {
                        id: uuid.v4(),
                        name: "picture",
                        type: "picture",
                        x: x,
                        y: y,
                        z: 1,
                        width: 80,
                        height: 50,
                        rotation: 0,
                        style: {
                            fgcol: "blue",
                            bgcol: "",
                            effects: false
                        },
                    }
                }
                if (obj !== null) {
                    this.pageContent.components.push(obj);
                    this.loadCanvasArrays();
                }
            }
        },
        enterEvent() {
            if (this.getEditMode) this.canvas.style.cursor = 'move';
        },
        leaveEvent() {
            this.canvas.style.cursor = 'default';
        },
        moveStart(id) {
            if (id !== null) {
                this.dragObject = id;
                console.log(this.dragObject);
            }
        },
        moveEvent(e, item) {
            console.log(e, item);
            const element = findObjectById(this.pageContent.components, item.name);
            if (element) {
                element.x = Math.round(e.target.attrs.x, 0);
                element.y = Math.round(e.target.attrs.y, 0);
                console.log(element.x, element.y);
            }
            this.dragObject = null;
        },
        transformEvent(e, item) {
            console.log(e, item);
            const element = findObjectById(this.pageContent.components, item.name);
            if (element) {
                let selectedNode = null;
                if (element.type === 'rect') selectedNode = this.rectTransformer.getStage().findOne("." + item.name);
                if (element.type === 'circle') selectedNode = this.circleTransformer.getStage().findOne("." + item.name);
                if (element.type === 'picture') selectedNode = this.pictureTransformer.getStage().findOne("." + item.name);
                if (element.type === 'text' || element.type === 'textvar') selectedNode = this.textTransformer.getStage().findOne("." + item.name);
                element.x = Math.round(e.target.attrs.x);
                element.y = Math.round(e.target.attrs.y);
                // element.width = Math.round(e.target.attrs.width * (e.target.attrs.scaleX));
                // element.height = Math.round(e.target.attrs.height * (e.target.attrs.scaleY));
                if (element.type === 'circle') {
                    element.radius = Math.round(e.target.attrs.radius * (e.target.attrs.scaleX));
                } else {
                    element.width = Math.round(e.target.attrs.width * (e.target.attrs.scaleX));
                    element.height = Math.round(e.target.attrs.height * (e.target.attrs.scaleY));
                }
                // element.width = Math.round(e.target.attrs.width);
                // element.height = Math.round(e.target.attrs.height);
                element.scaleX = 1;
                element.scaleY = 1;
                element.rotation = Math.round(e.target.attrs.rotation);
                if (selectedNode) {
                    selectedNode.scaleX(1);
                    selectedNode.scaleY(1);
                }
                console.log(this.sceneScale, element.x, element.y, element.width, element.height, element.scaleX, element.scaleY, element.rotation);
            }
            this.dragObject = null;
            this.loadCanvasArrays();
        },
        hideEditDiag() {
            // this.$emit('entryAbort');
            if (this.txtDiag) this.txtDiag = false;
            if (this.rectDiag) this.rectDiag = false;
            if (this.circleDiag) this.circleDiag = false;
        },
        handleDragStart() {
            this.isDragging = true;
        },
        handleDragEnd() {
            this.isDragging = false;
        },
        handleStageMouseDown(e) {
            if (e.target === e.target.getStage()) {
                this.selectedImageName = "";
                this.selectedNode = null;
                this.updateTransformer();
                return;
            }
            // clicked on transformer - do nothing
            const clickedOnTransformer = e.target.getParent().className === "Transformer";
            if (clickedOnTransformer) {
                return;
            }
            if (this.getEditMode) {
                // find clicked rect by its name
                const name = e.target.name();
                const images = Object.values(this.images).find(r => r.name === name);
                const texts = findObjectById(this.canvasTextVarArr, name);
                const rects = findObjectById(this.canvasRectArr, name);
                const circles = findObjectById(this.canvasCircleArr, name);
                const pictures = findObjectById(this.canvasPictureArr, name);
                if (images) {
                    this.selectedImageName = name;
                    this.selectedNode = this.imageGroup.find(
                    ".".concat(this.selectedImageName)
                    )[0];

                    this.selectedNode.moveToTop();
                }
                if (rects) {
                    this.selectedImageName = name;
                    this.selectedNode = this.rectGroup.find(
                    ".".concat(this.selectedImageName)
                    )[0];

                    this.selectedNode.moveToTop();
                }
                if (circles) {
                    this.selectedImageName = name;
                    this.selectedNode = this.circleGroup.find(
                    ".".concat(this.selectedImageName)
                    )[0];

                    this.selectedNode.moveToTop();
                }
                if (pictures) {
                    this.selectedImageName = name;
                    this.selectedNode = this.pictureGroup.find(
                    ".".concat(this.selectedImageName)
                    )[0];

                    this.selectedNode.moveToTop();
                }
                if (texts) {
                    this.selectedImageName = name;
                    this.selectedNode = this.textGroup.find(
                    ".".concat(this.selectedImageName)
                    )[0];

                    this.selectedNode.moveToTop();
                }
            }
            this.updateTransformer();
        },
        stageClick (event) {
            console.log(event);
            // let stage = this.$refs.stage.getStage()
            // // if click on empty area - remove all transformers
            // if (e.target === stage) {
            //     stage.find('Transformer').destroy();
            //     stage.draw();
            //     return;
            // }
        },
        textClick (event) {
            console.log(event);
            // let stage = this.$refs.stage.getStage()
            // stage.find('Transformer').destroy()
            // // create new transformer
            // var tr = new window.Konva.Transformer()
            // let layer = this.$refs.layer.getStage(tr)
            // tr.attachTo(e.target) // no idea what to do here ...
            // layer.add(tr);
            // layer.draw()
        },
        calculateCanvas() {
            // this.size.width = this.pageArea.offsetWidth;
            // this.size.height = this.pageArea.offsetWidth/1.78;
            this.size.width = this.pageArea.offsetWidth;
            this.size.height = this.pageArea.offsetWidth/(SCENE_BASE_WIDTH/SCENE_BASE_HEIGHT);
            this.canvas.height = this.size.height;
            // this.size.height = this.pageArea.offsetWidth/(SCENE_BASE_WIDTH/SCENE_BASE_HEIGHT);
            this.sceneScale = this.size.width / SCENE_BASE_WIDTH;
            // this.sceneScale = 1;
            this.stageArea.size({ width: this.size.width, height: this.size.height });
            this.stageArea.scale({ x: this.sceneScale, y: this.sceneScale });
            console.log(this.size.width);
            console.log(this.size.height);
        },
        checkSize() {
            this.size = {
                width: window.innerWidth,
                height: window.innerHeight
            };
            this.calculateCanvas();
            const _uuid = uuid.v4();
            console.log(_uuid);
        },
        changeRect: function() {
            const container = this.$refs.container;

            if (!container) {
                return;
            }

            const height = container.offsetHeight;
            const width = container.offsetWidth;

            console.log(width, height);
            // this.stageSize.width = width;
            // this.stageSize.height = height;
            this.stageSize.width = this.width % 2 !== 0 ? this.width - 1 : this.width;
            this.stageSize.height = this.height % 2 !== 0 ? this.height - 1 : this.height;
        },
        transformerText() {
            const selectedNode = this.$refs.transformer
                .getStage()
                .getStage()
                .findOne("." + this.selectedShapeName);

            this.v_text[0].width = this.v_text[0].width * selectedNode.getScaleX();

            selectedNode.scaleX(1);
            selectedNode.scaleY(1);
        },
        transformerImage() {
            const selectedNode = this.$refs.transformer
                .getStage()
                .getStage()
                .findOne("." + this.selectedShapeName);
                selectedNode.scaleX(1);
            selectedNode.scaleY(1);
        },
        updateTransformer() {
            console.log("lala")
            // here we need to manually attach or detach Transformer node
            const transformerNode = this.transformer;
            if (this.cropMode) {
                transformerNode.detach();
            } else if (this.selectedNode === transformerNode.node()) {
                // do nothing if selected node is already attached
                return;
            } else if (this.selectedNode) {
                // attach to another node
                this.selectedNode.moveToTop();
                transformerNode.attachTo(this.selectedNode);
            } else {
                // remove transformer
                transformerNode.detach();
            }
            transformerNode.getLayer().batchDraw();
        },
        registerImages() {
            this.imagesProps.forEach((url, index) => {
                if (!this.images[url]) {
                    let img = new Image();
                    img.src = url;
                    img.onload = () => {
                        this.images[url] = {
                            id: url,
                            image: img,
                            draggable: true,
                            name: url,
                            x: 0,
                            y: 0,
                            zIndex: index
                        }
                    };
                }
            });
        },
        removeSelectedImage() {
            let newImageProps = this.imagesProps.filter(
                url => url !== this.selectedImageName
            );
            this.$emit("update:image-props", newImageProps);
            
            this.$delete(this.images, this.selectedImageName);
            this.selectedNode = null;
                this.updateTransformer();
            
            // this.stageArea.draw();
        },
        itemTitle(item) {
            let result = item.i;
            if (item.static) {
                result += ' - Static';
            }
            return result;
        },
        checkEditMode() {
            if (this.$store.getters.getPageData.editMode === true) {
                this.editMode = true;
            } else {
                this.editMode = false;
            }
        },
        generateId() {
            // const rndm = getRandomInt(0, 65535);
            const rndm = uuid.v4();
            const index = findIndexByTag(this.pageContent.components, 'i', rndm);
            if (index === -1) {
                return rndm;
            } else {
                this.generateId();
            }
        },
        duplicateWidget(settings) {
            let index;
            let widget;
            // this.grids = [...this.grids, 1];
            // index = (this.grids.length - 1);
            index = this.pageContent.components.length;
            console.log(index);
            // this.pageContent.components[index] = [];
            console.log(settings);
            if (settings !== null) {
                widget = { ...settings };
                widget.y = widget.y + 2;
                // widget.id = index;
                const rndm = this.generateId();
                widget.i = rndm;
            }
            this.changeVal(widget);
        },
        addWidget(settings) {
            let index;
            let widget;
            // this.grids = [...this.grids, 1];
            // index = (this.grids.length - 1);
            index = this.pageContent.components.length;
            console.log(index);
            // this.pageContent.components[index] = [];
            console.log(settings);
            if (settings !== null) {
                let data = null;
                if (settings.isArray) {
                    data = [
                        {
                            id: '01'
                        },
                        {
                            id: '02'
                        }
                    ]
                } else {
                    data = {
                        id: '01'
                    }
                }
            const rndm = this.generateId();
              const lastWidget = {...this.pageContent.components[index-1]};
                widget = {
                    // id: index,
                    // id: getRandomInt(0, 65535),
                    // i: getRandomInt(0, 65535),
                    i: rndm,
                    name: 'Neues Widget',
                    label: 'Neues Widget',
                    component: settings.component,
                    width: 2,
                    style: {
                        background: 'darkBlue',
                        foreground: 'white',
                    },
                    data: data,
                    static: false,
                    moved: false,
                    y: lastWidget.y + 2,
                    x: lastWidget.x,
                    h: 2,
                    w: 2,
                };
            }
            // this.pageContent.components.push(widget);
            this.changeVal(widget);
            // console.log(this.pageContent.components);

            // index2 = this.pageContent.components[index].length;
            // console.log(index2);
            // this.pageContent.components[index][index2] = {};
            // this.pageContent.components[index][index2].id = index + ":" + index2;
            // this.pageContent.components[index][index2].name = "GUSCH";
            // console.log(this.grids);
        },
        dragOff(event) {
            console.log(event);
            this.changeVal(null);
            this.drag = false;
            // console.log(this.grids3);
        },
        getIndex(id) {
            var index = this.pageContent.components.findIndex((x) => x.id === id);
            if (index === -1) {
                // this.values.push(vars);
                console.log('NOT FOUND');
                return -1;
            } else {
                // this.values[index] = vars;
                // return this.pageContent.components[index];
                return index;
            }
        },
        getGridIndex(id) {
            var index = this.pageContent.components.findIndex((x) => x.id === id);
            if (index === -1) {
                // this.values.push(vars);
                console.log('NOT FOUND');
                return -1;
            } else {
                // this.values[index] = vars;
                // return this.pageContent.components[index];
                return index;
            }
        },
        savePage(callback) {
            SocketioService.setViewPage(this.pageContent, (err, response) => {
                if (err || response === null) {
                    callback(false);
                } else {
                    // this.loading = false;
                    const result = mongoResponseToast(response, this.$root);
                    if (result) callback(true);
                }
            });
        },
        changeVal(entry) {
            console.log(">>> PAGE CHANGEVAL:", entry);
            if (entry !== null) {
                const index = this.getGridIndex(entry.id);
                console.log('INDEX is: ' + index);
                if (index === -1) {
                    this.pageContent.components.push(entry);
                } else {
                    if (entry.remove) {
                        this.pageContent.components.splice(index, 1);
                    } else if (entry.copy) {
                        delete entry.copy;
                        entry.id = uuid.v4();
                        this.pageContent.components.push(entry);
                    } else {
                        this.pageContent.components[index] = entry;
                    }
                }
                this.loadCanvasArrays();
            }
        },
        duplicateVal(entry) {
            this.duplicateWidget(entry);
        },
        deleteVal(entry) {
            const index = this.getGridIndex(entry.i);
            this.pageContent.components.splice(index, 1);
        },
        onNodeSelect(node) {
            this.$toast.add({
                severity: 'success',
                summary: 'Node Selected',
                detail: node,
                life: 3000,
            });
        },
        getParameterStatusIOTree() {
            //store.dispatch('getIoTree', null);
        },
        sortAfterZ(a, b) {
            const zA = a.z !== undefined ? a.z : -Infinity;
            const zB = b.z !== undefined ? b.z : -Infinity;
            
            return zB - zA;
        },
    },
});
</script>

<style lang="scss" scoped>
.content {
    height: 100%;
    width: 100%;
    border: 2px solid #42b983;
    border-radius: 5px;
}
.menu-overlay {
    top: 0px;
    right: 0px;
    position: absolute;
    height: 100px;
    opacity: 0;
    transition: 0.2s ease;
    background: none;
    text-align: right;
}

.card:hover .menu-overlay {
    opacity: 1;
}

.menu-overlay .p-button-rounded {
    width: 2rem;
    height: 2rem;
}
</style>
